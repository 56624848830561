import pageheader from "../../common/pageheader";
import rules from "../../../utils/rules";
export default {
  name: "report",
  components: { pageheader },
  props: ["id"],
  data() {
    return {
      rules: rules,
      valid: true,
      isLoading: false,
      searchAudit: "",
      SelectedCustomer: "",
      selectedServiceType: "",
      serviceTypes: [
        {
          description: "SPOT",
          id: "INST-SPOT",
          type: "INST-SPOT"
        },
        {
          description: "RAW (3 wire install)",
          id: "INST-S",
          type: "INST-S"
        }
      ],
      CustomersList: [
        {
          customerName: "[Trackunit US] Audit Account",
          id: "[Trackunit US] Audit Account"
        },
        {
          customerName: "[TU] Audit Account",
          id: "[TU] Audit Account"
        }
      ],
      input: {
        icon: "mdi-file-chart-outline",
        name: "Reports",
        description:
          "<div>Here you can download and schedule the reports for your assets.<br> Based on your subscription plan different reports will be available.</div>"
      },
      search: "",
      desserts: [
        {
          customerName: "Bigge Crane Bigge Crane Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer Liebheer Liebheer Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane Bigge Crane Bigge Crane Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer Liebheer Liebheer Liebheer Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Bigge Crane",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        },
        {
          customerName: "Incus",
          branchName: 159,
          tuSerial: "3657743",
          serviceType: "INST-S",
          serviceStatus: "CCC200141",
          machineId: "0000",
          machineYear: "134028",
          manufactureName: "Liebheer",
          model: "LR1160",
          machineDescription: "Crawler",
          machineType: "Crane",
          serviceDate: "2021-07-09"
        }
      ],
      headers: [
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "customerName",
          width: "120px"
        },
        { text: "Branch Name", value: "branchName" },
        { text: "TU Serial", value: "tuSerial" },
        { text: "Service Type", value: "serviceType" },
        { text: "Service Status", value: "serviceStatus" },
        { text: "Machine ID", value: "machineId" },
        { text: "Machine Year", value: "machineYear" },
        { text: "Manufacture Name", value: "manufactureName", width: "120px" },
        { text: "Model", value: "model" },
        { text: "Machine Description", value: "machineDescription" },
        { text: "Machine Type", value: "machineType" },
        { text: "Service Date", value: "serviceDate" }
      ]
    };
  },
  computed: {},
  mounted() {
    if (this.id == "Exception_Report") {
      this.input = {
        icon: "mdi-file-chart-outline",
        name: "Exception Report",
        description: "<div>Activity Deviation.</div>"
      };
    } else if (this.id == "Utilization_Report") {
      this.input = {
        icon: "mdi-file-chart-outline",
        name: "Utilization Report",
        description: "<div>Exeeding utilisation rate.</div>"
      };
    }
  },
  methods: {}
};
