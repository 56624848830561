var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"report"},[_c('pageheader',{attrs:{"input":_vm.input}}),_c('v-container',{staticClass:"container--fluid"},[_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"search-bar",attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1 sample-table",attrs:{"dense":"","fixed-header":"","height":"340","headers":_vm.headers,"items":_vm.desserts,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"customer-name"},'span',attrs,false),on),[_vm._v(_vm._s(item.customerName))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.customerName))])])]}},{key:"item.manufactureName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"manufacture-name"},'span',attrs,false),on),[_vm._v(_vm._s(item.manufactureName))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.manufactureName))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }